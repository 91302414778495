<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="自定义设置" name="first">
        <customSettings v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="分类管理" name="second">
         <classifiedManagement v-if="activeName == 'second'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import customSettings from './components/customSettings';
import classifiedManagement from './components/classifiedManagement';
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components:{
    customSettings,
    classifiedManagement
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>